<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.8579 2.06766C14.9101 1.7547 14.7797 1.44174 14.5189 1.25917C14.232 1.07661 13.8929 1.07661 13.6321 1.23309L1.94817 7.90964C1.66129 8.06612 1.50481 8.353 1.53089 8.66597C1.53089 9.00501 1.73953 9.26581 2.02641 9.39621L4.71268 10.5177L12.3542 3.63248L6.69478 10.9089C6.59046 11.0393 6.51222 11.2218 6.51222 11.4044V13.9342C6.51222 14.2211 6.77302 14.4558 7.0599 14.4558C7.24246 14.4558 7.42503 14.3775 7.52935 14.2211L8.85944 12.239L12.0412 13.5691C12.1456 13.6212 12.2499 13.6212 12.3542 13.6212C12.5107 13.6212 12.6411 13.5951 12.7715 13.5169C13.0062 13.4126 13.1627 13.1778 13.1888 12.917L14.8579 2.06766Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSendMessage'
}
</script>
